<template>
    <div class="topbar">
        <DxButton v-if="isAuthorized" text="Dashboard" @click="goHome" height="50px" :element-attr="dashboardButton"></DxButton>
        <div id="main-portal-navbar" class="d-flex justify-content-center align-items-center" v-if="isAuthenticated">
            <ul class="navbar-nav">
                <li class="dropdown d-flex ">
                    <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown">
                        <span>{{ givenName }}</span>
                    </a>
                    <ul class="dropdown-menu">
                        <li v-if="useMSAL && isLocalAccount"><router-link class="dropdown-item" to="/ChangePassword">Change Password</router-link></li>
                        <li v-if="useMSAL"><router-link class="dropdown-item" to="/Logout">Sign Out</router-link></li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
    <div class="middlebar">
        <div class="filter">
            <img src="@/assets/logo-text.svg" class="logoStyle" alt="logo" />

            <span class="pageTitle">{{pageTitle}}</span>
            <!--LOGO-->
            <!--Page Title-->
        </div>
    </div>
    <nav class="navbar navbar-dark ski-header navbar-expand-md ThemedBackground spacing">
        <div class="container-fluid">
            <button class="navbar-toggler d-md-none" type="button" data-bs-toggle="collapse" data-bs-target="#navbarHeader" aria-controls="navBarHeader" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="navbar-collapse collapse" id="navbarHeader" v-if="!onInfoPage && isAuthorized">
                <ul class="navbar-nav">
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown">Quoting</a>
                        <ul class="dropdown-menu">
                            <li v-for="routeData in quotingRoutes" :key="routeData.title">
                                <span v-if="onQuoteTool && routeData.key == 'Quote Tool'">
                                    <a href="/customer/quotetool" class="dropdown-item">{{routeData.title}}</a>
                                </span>
                                <span v-else>
                                    <router-link class="dropdown-item" :to="getRoute(routeData.route)">{{routeData.title}}</router-link>
                                </span>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown"> FAQs / Documents</a>
                        <ul class="dropdown-menu">
                            <li v-for="routeData in faqRoutes" :key="routeData.title">
                                <router-link class="dropdown-item" :to="getRoute(routeData.route)">{{routeData.title}}</router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown">Presentation Tools</a>
                        <ul class="dropdown-menu">
                            <li v-for="routeData in presentationRoutes" :key="routeData.title">
                                <router-link class="dropdown-item" :to="getRoute(routeData.route)">{{routeData.title}}</router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item dropdown">
                        <router-link class="nav-link dropdown-toggle" to="/customer/coopprogram">Co-Op Program </router-link>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown">My Account</a>
                        <ul class="dropdown-menu">
                            <li v-for="routeData in myAccountRoutes" :key="routeData.title">
                                <span v-if="routeData.title == 'Team Members'">
                                    <router-link class="dropdown-item" :to="getRoute(routeData.route)" v-if="false">{{routeData.title}}</router-link>
                                    <!--<router-link class="dropdown-item" :to="getRoute(routeData.route)" v-if="isLeader">{{routeData.title}}</router-link>-->
                                </span>
                                <span v-else>
                                    <router-link class="dropdown-item" :to="getRoute(routeData.route)">{{routeData.title}}</router-link>
                                </span>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item dropdown" v-if="isAdmin">
                        <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown">Admin</a>
                        <ul class="dropdown-menu">
                            <li v-for="routeData in adminRoutes" :key="routeData.title">
                                <router-link class="dropdown-item" :to="getRoute(routeData.route)">{{routeData.title}}</router-link>
                            </li>
                            <li v-if="isIT">
                                <router-link class="dropdown-item" to="/ActiveReports">Active Report Editor</router-link>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div class="navbar-collapse collapse" id="navbarHeader" v-if="onInfoPage || !isAuthorized">
                <ul class="navbar-nav" v-if="useMSAL && isLocalAccount">
                    <li class="nav-item">
                        <router-link class="nav-link" to="/ChangePassword">Change Password</router-link>
                    </li>
                </ul>
                <ul class="navbar-nav" v-if="useMSAL">
                    <li class="nav-item">
                        <router-link class="nav-link" to="/Logout">Sign Out</router-link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script lang="ts">
    import { Options, Vue } from 'vue-class-component'
    import { IdentificationModule } from '@/store/modules/identification/identification'
    import { CustomPageDTO } from '../model/CustomPageDTO'
    import { MarkupRouteModule } from '../store/modules/MarkupRouteModule'
    import { DxButton } from 'devextreme-vue/button'
    import { AzureIdentificationModule } from '@/store/modules/identification/azureIdentification'
    import { UserAccountModule, UserAccountInfo } from '@/store/modules/useraccount'

    @Options({
        components: {
            DxButton
        }
    })
    export default class Header extends Vue {
        get onQuoteTool(): boolean {
            return this.$router.currentRoute.value.path == '/customer/quotetool';
        }

        get useMSAL(): boolean {
            return process.env.VUE_APP_USE_MSAL === 'true'
        }

        get isLocalAccount(): boolean {
            return AzureIdentificationModule.isLocalAccount
        }

        get identified(): boolean {
            return IdentificationModule.identified ?? false
        }

        get isAuthenticated(): boolean {
            if (this.useMSAL) {
                return AzureIdentificationModule.authenticated
            } else {
                return this.identified
            }
        }

        get isAuthorized(): boolean {
            if (this.useMSAL) {
                return !this.newUser
            } else {
                return true
            }
        }

        get newUser(): boolean {
            return !AzureIdentificationModule.isInRole('Portal-Customer')
        }

        get givenName(): string | void {
            /* eslint-disable camelcase */
            if (this.useMSAL) {
                if (this.user == undefined) {
                    return "Welcome"
                }
                if (this.user.preferredName != null && this.user.preferredName.length > 0) {
                    return this.user.preferredName
                }
                let displayname = this.user.fullName 
                if (!this.isLocalAccount) {
                    return displayname ?? AzureIdentificationModule.displayName ?? AzureIdentificationModule.portalEmail ?? "Welcome"
                }
                return displayname ?? AzureIdentificationModule.portalEmail ?? "Welcome"
            } else {
                return IdentificationModule.tokenData?.given_name
            }
            /* eslint-ensable camelcase */
        }

        get user(): UserAccountInfo {
            return UserAccountModule.currentUser
        }

        get onInfoPage(): boolean {
            return this.$router.currentRoute.value.fullPath.toLowerCase() === '/account/information'
        }

        get baseUrl(): string {
            return window.location.origin
        }

        get pageTitle(): string {
            const title = this.$route.meta.title as string
            if (title) {
                return title.toUpperCase()
            }
            return ''
        }

        get quotingRoutes(): CustomPageDTO[] {
            try {
                return MarkupRouteModule.routeData.filter(data => data.categoryName.trim() == 'Quoting').sort(x => x.order);
            } catch {
                return []
            }
        }

        get faqRoutes(): CustomPageDTO[] {
            try {
                return MarkupRouteModule.routeData.filter(data => data.categoryName.trim() == 'FAQs / Documents').sort(x => x.order);
            } catch {
                return []
            }
        }

        get presentationRoutes(): CustomPageDTO[] {
            try {
                return MarkupRouteModule.routeData.filter(data => data.categoryName.trim() == 'Presentation Tools').sort(x => x.order);
            } catch {
                return []
            }
        }

        get myAccountRoutes(): CustomPageDTO[] {
            try {
                return MarkupRouteModule.routeData.filter(data => data.categoryName.trim() == 'My Account').sort(x => x.order);
            } catch {
                return []
            }
        }

        get adminRoutes(): CustomPageDTO[] {
            try {
                return MarkupRouteModule.routeData.filter(data => data.categoryName.trim() == 'Admin').sort(x => x.order);
            } catch {
                return []
            }
        }

        get isIT(): boolean {
            return AzureIdentificationModule.isInRole('IT')
        }

        get isAdmin(): boolean {
            return AzureIdentificationModule.isInRole('SalesAdmin') || this.isIT
        }

        get isLeader(): boolean {
            return AzureIdentificationModule.isInRole('DealerTeamLeader') || this.isAdmin
        }

        getRoute(route: string) {
            return `${route}`;
        }

        goHome(): void {
            this.$router.push('/customer')
        }

        private dashboardButton = {
            class: 'dashboardButton',
            id: 'dashboardB'
        }
    }
</script>

<style scoped lang="scss">
    .ski-header {
        padding: 5px 15px;
        margin-bottom: 15px;
    }

    .spacing {
        margin-bottom: 10px;
        padding-left: 3%;
        color: white;
        align-content: center;
    }

    .topbar {
        width: 100%;
        height: 50px;
        background-image: linear-gradient(to bottom,#3c3c3c,#161616);
        color: white;
        padding-right: 4%;
        padding-left: 4%;
    }

    #main-portal-navbar {
        float: right;
        color: white;
        width: 50px;
        height: 50px;
        padding-right: 4%;
        padding-left: 4%;
        margin-bottom: auto;
        margin-top: auto;
    }

    .middlebar {
        height: 90px;
        width: 100%;
        color: #337a52;
        background: url("@/assets/bg-interior-default.jpg");
    }

    .logoStyle {
        opacity: 1;
        height: 90px;
        padding-top: 20px;
        padding-bottom: 20px;
        margin-left: 16%;
        float: left;
    }

    #dashboardB.dashboardButton {
        background-color: #337a52;
        color: #ffffff;
        outline: none;
        border: none;
        min-width: 200px;
        border-radius: 0;
        line-height: 20px;
        float: left;
        background-image: linear-gradient(to bottom,#337a52,#1c442e);
    }

    .pageTitle {
        font-size: 2em;
        line-height: 90px;
        font-family: Raleway,sans-serif;
        text-align: center;
        font-weight: 800;
        align-self: center;
    }

    .filter {
        background-color: rgba(255,255,255,.93);
        width: 100%;
        display: grid;
        grid-template-columns: 25vw 50vw 25vw;
    }

    .nav-link {
        color: rgb(230, 230, 230) !important;
    }
</style>
